import React from 'react'
import { connect } from 'react-redux'
import Image from './Images'

import Style from '../styles/components/collaborationsReleases.module.scss'

const LayoutSmall = (props) => (
  <>
    { props.items &&
    <div className={Style.Releases}>
      { props.items.map((item, index) => {
        return (
          <div className={Style.ReleasesContainer} key={`releases-${index}`}>
            <div className="row">
              <div className={`${Style.imageSquare} ${Style.col6} col-6 no-padding`}>
                <Image src={item.image.url} alt={item.image_alt_attribute} />
              </div>
              <div className={`${Style.textSquare} ${Style.col6} col-6 no-padding`}>
                <div>
                  <p>{item.text.text}</p>
                  <span>{item.company.text}</span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div> }
  </>
);

const LayoutDefault = (props) => (
  <>
    { props.items &&
    <div className={Style.Releases}>
      { props.items.map((item, index) => {
        return (
          <div className={Style.ReleasesContainer} key={`releases-${index}`}>
            <div className="row">
              { index % 2 === 0 ? (
                <>
                  <div className={`${Style.textSquare} ${Style.col6} col-6 no-padding`}>
                    <div>
                      <p>{item.text.text}</p>
                      <span>{item.company.text}</span>
                    </div>
                  </div>
                  <div className={`${Style.imageSquare} ${Style.col6} col-6 no-padding`}>
                    <Image src={item.image.url} alt={item.image_alt_attribute} />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${Style.imageSquare} ${Style.col6} col-6 no-padding`}>
                    <Image src={item.image.url} alt={item.image_alt_attribute} />
                  </div>
                  <div className={`${Style.textSquare} ${Style.col6} col-6 no-padding`}>
                    <div>
                      <p>{item.text.text}</p>
                      <span>{item.company.text}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      })}
    </div> }
  </>
);

const CollaborationsReleases = (props) => (props.viewportSize === 'small' || props.viewportSize === 'medium')
  ? <LayoutSmall items={props.items} twic={props.twic} />
  : <LayoutDefault items={props.items} twic={props.twic} />

const mapStateToProps = (state) => ({
  viewportSize: state.viewport.viewportSize
});

export default connect(mapStateToProps)(CollaborationsReleases);
