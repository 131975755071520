import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import CollaborationsFilters from '../components/Filters/CollaborationsFilters'
import Releases from '../components/CollaborationsReleases'
import ListingStyle from '../styles/pages/collaborationCategory.module.scss';

const CollaborationsHome = ({ pageContext, data }) => {
  const staticPrismicTexts = data.staticText.edges[0].node.data.texts;
  const page = data.collaborations.edges[0].node.data;
  const releases = page.releases.document[0].data.releases;

  /* get all collab items */
  const collaborationsItems = data.collaborationsItems.edges.map((i) => {
    return {
      uid: i.node.uid,
      logo: i.node.data.logo.url,
      title: i.node.data.title.text,
      filters: i.node.data.filters.map((_i) => {
        return {
          anchor: _i.filter && _i.filter.document[0].data.anchor,
          label: _i.filter && _i.filter.document[0].data.label.text
        }
      })
    }
  });
  
  return (
    <Layout
      context={pageContext}
      pageTitle={pageContext.title}
      _t={staticPrismicTexts}
    >
      <SEO
        pageTitle={page.seo__meta_title_.text}
        pageDescription={page.seo__meta_description_.text}
        context={pageContext}
      />

      <div className="page">
        <section className="regular">
          <div className="page-head">
            <h2>{page.page_title.text}</h2>
            <div
              className="page-header-text"
              dangerouslySetInnerHTML={{
                __html: page.page_header_text.html,
              }}
            ></div>
            <div>
              <div>
                <p className="center bold" style={{ paddingBottom: '15px' }}>
                  {page.subtitle_categories.text}
                </p>
              </div>
            </div>
            <CollaborationsFilters {...pageContext} _t={staticPrismicTexts} />
          </div>
          <div className="container" style={{marginTop: 30}}>
            <div className={`row ${ListingStyle.Companies}`}>
              {collaborationsItems &&
                collaborationsItems.map((data, index) => {
                  return (
                    <div 
                      className={`col-4 _companies ${ListingStyle.CompaniesContainer}`} key={`company-${index}`}
                      style={{ padding: '5px' }}
                    >
                      <Link to={`/${pageContext.langKey}/collaborations/${data.uid}/`}>
                        <img src={data.logo} alt={data.title} />
                      </Link>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        <section className={`${ListingStyle.Releases}`}>
          <div>
            <p className="center bold">{page.subtitle_releases.text}</p>
          </div>
          <Releases items={releases} twic={pageContext.twic} />
        </section>
      </div>
    </Layout>
  )
}

export default CollaborationsHome;
export const pageQuery = graphql`
  query CollaborationsHome($locale: String!) {
    collaborations: allPrismicPageCollaborations(filter: { lang: { eq: $locale} }) {
      edges { ...collaborations }
    }
    collaborationsItems: allPrismicContentCollaboration(filter: { lang: { eq: $locale} }) {
      edges {
        node {
          uid
          data {
            logo {
              url
            }
            title {
              text
            }
            filters {
              filter {
                document {
                  data {
                    label {
                      html
                      text
                    }
                    anchor
                  }
                }
              }
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`